import config from './config';
import firebase from 'firebase/app';
//require("firebase/firestore");
require("firebase/auth");
//require("firebase/storage");

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const googleProvider = new firebase.auth.GoogleAuthProvider();
//export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const firebaseAuth = firebase.auth;
export const firebaseAuth2 = firebase.auth();
//export const firestore = firebase.firestore();
//export const storage = firebase.storage();
//export const db = firebase.database(); 