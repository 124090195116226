import React, { Component } from "react";
import Layout from "../components/layout";
import axios from "axios";
import Card from "../components/card";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      poids: 0,
      reference: "LG1201",
      month: "13",
      EmailButtonLoading: false,
      disableClear: true,
      disableField: false,
      active: false,
      show: false,
      //show : true,
      results: {},
      loading: false,
    };
    this.togglePoids = this.togglePoids.bind(this);
    this.update = this.udpate.bind(this);
    this.clear = this.clear.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelect2 = this.handleSelect2.bind(this);
  }

  handleSelect(event) {
    this.setState((prevState) => {
      return {
        ...prevState,
        reference: event.target.value,
        month:
          event.target.value === "LG1201" ||
          event.target.value === "LG1202" ||
          event.target.value === "LG1202C" ||
          event.target.value === "NATD1202" ||
          event.target.value === "GF1202" ||
          event.target.value === "GF1205"
            ? prevState.month
            : "13",
      };
    });
  }

  handleSelect2(event) {
    this.setState({ month: event.target.value });
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState, show: false });
  }

  clear() {
    this.setState({
      disableClear: true,
      disableField: false,
      show: false,
    });
  }

  udpate() {
    var currentComponent = this;
    this.setState(
      {
        disableClear: false,
        disableField: true,
        loading: true,
      },
      function () {
        axios
          .get(
            "https://cms.ultrapremiumdirect.com/api/b2c-rations/getReco",
            {
              params: {
                weight: this.state.poids,
                ref: this.state.reference,
                month: this.state.month,
              },
            },
            {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
                Authorization: "Bearer " + this.props.token,
              },
            }
          )
          .then((response) => {
            currentComponent.setState({
              results: response.data,
              show: true,
              loading: false,
            });
          })
          .catch((error) => {
            currentComponent.setState({
              results: error.response.data,
              active: true,
              disableClear: true,
              disableField: false,
              loading: false,
            });
          });
      }
    );
  }

  togglePoids(event) {
    this.setState({ poids: event.target.value });
  }

  render() {
    //console.log(this.state)
    return (
      <Layout>
        <section className="hero is-large">
          <div className="hero-body">
            <div className="container is-widescreen">
              <div className="columns is-vcentered is-centered">
                <div className="column is-narrow">
                  <h1 className="title is-3">Calculateur de rations</h1>
                </div>
              </div>
              <div className="columns is-vcentered is-multiline">
                <div className="column is-6">
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field is-fullwidth has-addons">
                        <p className="control">
                          <a className="button is-static">KG</a>
                        </p>
                        <div className="control">
                          <input
                            className="input"
                            name="poids"
                            value={this.state.poids}
                            type="number"
                            placeholder="Poids"
                            onChange={this.togglePoids}
                            min="1"
                            disabled={this.state.disableField}
                          />
                        </div>
                        <p className="control">
                          <a className="button is-static">.00</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <div className="select">
                            <select
                              value={this.state.reference}
                              onChange={this.handleSelect}
                              disabled={this.state.disableField}
                            >
                              <option value="LG1201C">
                                LG1201C - Croquettes Super Premium pour Chiots
                                de Petite et Moyenne Taille (inf. 30 kg)
                              </option>
                              <option value="LG1202C">
                                LG1202C - Croquettes Super Premium pour Chiots
                                de Grande Taille (sup. 30 kg)
                              </option>
                              xs
                              <option value="LG0803C">
                                LG0803C - Croquettes Super Premium pour Chiens
                                de Petite Taille (inf. 10 kg)
                              </option>
                              <option value="LG1204C">
                                LG1204C - Croquettes Super Premium pour Chiens
                                de Taille Moyenne (10 kg à 30 kg)
                              </option>
                              <option value="LG1205">
                                LG1205 - Croquettes Super Premium pour Chiens de
                                Grande Taille (sup. 30 kg)
                              </option>
                              <option value="LG1206">
                                LG1206 - Croquettes Super Premium pour Chiens
                                Actifs de Toutes Tailles
                              </option>
                              <option value="LG1207C">
                                LG1207C - Croquettes Super Premium pour Chiens
                                Saumon et Riz
                              </option>
                              <option value="LG1208">
                                LG1208 - Croquettes Super Premium pour Chiens
                                Agneau et Riz
                              </option>
                              <option value="LG1209">
                                LG1209 - Croquettes Light Super Premium pour
                                Chiens en Surpoids / Stérilisé
                              </option>
                              <option value="LG1210">
                                LG1210 - Croquettes Super Premium pour Chiens
                                Senior
                              </option>
                              <option value="LG1211B">
                                LG1211B - Croquettes hypoallergéniques pour
                                Chiens au Poisson - Love & Care
                              </option>
                              <option value="LG1212B">
                                LG1212B - Croquettes Light pour Chiens adultes
                                en Surpoids ou Stérilisés - Love & Care
                              </option>
                              <option value="LG1213">
                                LG1213 - Croquettes pour Chien Senior
                              </option>
                              <option value="GF1201">
                                GF1201 - Croquettes Sans Céréales- Country Farm
                                - Chien Adulte petit et moyen (inf. 20 kg)
                              </option>
                              <option value="GF1201B">
                                GF1201B - Croquettes Sans Céréales- Country Farm
                                - Chien Adulte petit et moyen (inf. 20 kg)
                              </option>
                              <option value="GF1202B">
                                GF1202B - Croquettes Sans Céréales pour Chiots
                                Toutes Tailles
                              </option>
                              <option value="GF1203">
                                GF1203 - Croquettes Sans Céréales - Original -
                                Chien Adulte de Grande Taille (sup. 30 kg)
                              </option>
                              <option value="GF1204">
                                GF1204 - Croquettes Sans Céréales - NATDural
                                Hunter - Chien Adulte de Taille Moyenne (20 kg à
                                30 kg)
                              </option>
                              <option value="GF1205">
                                GF1205 - Croquettes Sans Céréales pour Chiot
                                Sensible Toutes Tailles
                              </option>
                              <option value="GF1206">
                                GF1206 - Croquettes Sans Céréales pour Chien
                                Sensible Toutes Tailles
                              </option>
                              <option value="GF1207">
                                GF1207 - Croquettes sans céréales pour Chien
                                Sensible Toutes Tailles
                              </option>
                              <option value="BST0801">
                                BST0801 - Croquettes Protein Boost - Poulet
                              </option>
                              <option value="BST0802">
                                BST0802 - Croquettes Protein Boost - Agneau
                                &amp; Truite
                              </option>
                              <option value="LG0714">
                              LG0714 - Croquettes Super Premium pour chien stérilisé de petite taille
                              </option>
                              <option value="NATD1201">
                              NATD1201 - Croquettes sans céréales pour chien adulte au poulet frais
                              </option>
                              <option value="NATD1202">
                              NATD1202 - Croquettes sans céréales pour chiot au poulet frais
                              </option>
                              <option value="NATD1203">
                              NATD1203 - Croquettes sans céréales pour chien adulte au saumon frais
                              </option>
                              {/*
                                                            <option value="LG1203">LG1203 - Croquettes Super Premium pour Chiens de Petite Taille (inf. 10 kg)</option>
                                                            <option value="LG1211">LG1211 - Croquettes hypoallergéniques pour Chiens au Poisson - Love & Care</option>
                                                            <option value="LG1212">LG1212 - Croquettes Light pour Chiens adultes en Surpoids ou Stérilisés - Love & Care</option>
                                                            <option value="GF1202">GF1202 - Croquettes Sans Céréales pour Chiots Toutes Tailles</option>
<option value="LG1201">LG1201 - Croquettes Super Premium pour Chiots de Petite et Moyenne Taille (inf. 30 kg)</option>*/}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.reference === "LG1201" ||
                  this.state.reference === "LG1202" ||
                  this.state.reference === "LG1202C" ||
                  this.state.reference === "LG1201C" ||
                  this.state.reference === "GF1202" ||
                  this.state.reference === "GF1202B" ||
                  this.state.reference === "NATD1202" ||
                  this.state.reference === "GF1205" ? (
                    <div className="field">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="select">
                              <select
                                value={this.state.month}
                                onChange={this.handleSelect2}
                                disabled={this.state.disableField}
                              >
                                <option value="1"> - Age (mois) -</option>
                                <option value="2"> 2 mois</option>
                                <option value="3"> 3 mois</option>
                                <option value="4"> 4 mois</option>
                                <option value="5"> 5 mois</option>
                                <option value="6"> 6 mois</option>
                                <option value="7"> 7 mois</option>
                                <option value="8"> 8 mois</option>
                                <option value="9"> 9 mois</option>
                                <option value="10"> 10 mois</option>
                                <option value="11"> 11 mois</option>
                                <option value="12"> 12 mois</option>
                                <option value="13"> Plus de 12 mois</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field is-grouped">
                        <p className="control">
                          <button
                            className={
                              this.state.loading
                                ? "button is-success is-loading"
                                : "button is-success"
                            }
                            disabled={this.state.disableField}
                            onClick={() => {
                              this.update();
                            }}
                            type="submit"
                          >
                            Calculer
                          </button>
                        </p>
                        <p className="control">
                          <button
                            className="button is-danger"
                            disabled={this.state.disableClear}
                            onClick={() => {
                              this.clear();
                            }}
                            type="submit"
                          >
                            Nouveau calcul
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.show ? (
                this.state.loading ? null : (
                  <Card
                    data={this.state.results}
                    reference={this.state.reference}
                    poids={this.state.poids}
                    month={this.state.month}
                  />
                )
              ) : null}
            </div>
          </div>
        </section>
        <div className={this.state.active ? "modal is-active" : "modal"}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Erreur</p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.toggleClass}
              ></button>
            </header>
            <section className="modal-card-body">
              <p>Une erreur s'est produite!</p>
              <p>
                <strong>{this.state.results.errorText}</strong>
              </p>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-primary is-uppercase home-button"
                onClick={this.toggleClass}
              >
                Ok
              </button>
            </footer>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;
