
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../static/img/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
//import {faInstagram} from "@fortawesome/free-brands-svg-icons"
import { firebaseAuth } from '../config/firebase';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdd: true
        }
        this.signOut = this.signOut.bind(this);
    }

    toggleMenu() {
        this.props.toggleMenu();
    }

    signOut() {
        localStorage.setItem('authed', false);localStorage.setItem('authorized', false);
        firebaseAuth().signOut();
    }


    render() {
        return (

            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container is-widescreen">
                    <div className="navbar-brand">
                        <div className="navbar-item">
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>
                        </div>
                        <a role="button" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
                            className={this.props.openMenu ? 'navbar-burger burger is-active' : 'navbar-burger burger'} onClick={() => { this.toggleMenu() }}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div className={this.props.openMenu ? 'navbar-menu is-active' : 'navbar-menu'}>
                        <div className="navbar-end">
                        <Link to="/" className="navbar-item">
                                Calculateur de rations
                            </Link>
                            <Link to="/guide" className="navbar-item">
                                Guide animal
                            </Link>
                            <Link to="/reco" className="navbar-item">
                                Moteur de recos
                            </Link>
                            <Link to="/binutrition" className="navbar-item">
                                Binutrition Chat
                            </Link>
                            <div className="navbar-item">
                                <button className="button custom-font is-primary" onClick={() => { this.signOut() }}><FontAwesomeIcon width="16" icon={faSignOutAlt} />&nbsp;Déconnexion</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav >
        )
    }
}

export default Header