import React, { Component } from 'react';
import Logo from '../static/img/logo/logo.png'

class Card extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={Logo} alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="media-content"><p className="title is-4">Résultat du calcul</p>
                            <p className="subtitle is-6"><span className="tag is-large is-primary">
                                REF {this.props.reference}
                            </span></p>
                        </div>
                    </div>
                    <div className="content">
                        <p>Pour un chien de <strong>{this.props.month === "13" ? "plus de 12 mois" : this.props.month + " mois"}</strong> qui pèse <strong>{this.props.poids}kg</strong> : </p>
                        <p><b>Ration : </b>{this.props.data.ration} grammes/jour</p>
                        <hr />
                        <p><b>Gobelets : </b>{this.props.data.gobelet} {this.props.data.gobelet<2?"gobelet":"gobelets"}</p>
                        <hr />
                        <p><b>Durée (sac 12kg) : </b>{this.props.data.jours} jours</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card